<template>
    <div>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Código*"
          v-model="match.code"
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
           <div v-if="item.name">{{ item.name }}</div>
           <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Ubicación*"
          v-model="match.location"
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Nombre*"
          v-model="match.name"
          return-object
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Costo unitario*"
          v-model="match.unit_cost"
          return-object
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Unidad de medida"
          v-model="match.measurement_unit_name"
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Categoria"
          v-model="match.category_name"
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Descripción"
          v-model="match.description"
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-btn  color="orange" elevation="0"
        :style="buttonStyle" block
        :disabled="!match.code || !match.location || !match.name || !match.unit_cost"
         @click="uploadFile()" :loading="inventoryStore.loader_upload">Guardar cambios</v-btn>
        <v-btn  class="mt-2" elevation="0" block
        :disabled="inventoryStore.loader_upload"
         @click="handleBack()">Volver</v-btn>
    </div>
</template>
<script>

import { useInvertoryStore } from '@/inventory/store/inventory';

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    inventoryStore: useInvertoryStore(),
    target_columns: [],
    match: {
      code: null,
      location: null,
      name: null,
      unit_cost: null,
      measurement_unit_name: null,
      category_name: null,
      description: null,
    },
    mapheaders: {
      code: 'Código',
      location: 'Ubicación',
      name: 'Nombre',
      unit_cost: 'Costo unitario',
      measurement_unit_name: 'Unidad de medida',
      category_name: 'Categoría',
      description: 'Descripción',
    },
  }),
  watch: {
    columns: {
      handler(val) {
        this.autoSelectColumns();
        this.target_columns = val;
      },
      deep: true,
    },
  },
  computed: {
    buttonStyle() {
      return {
        color: this.match.code && this.match.location && this.match.name && this.match.unit_cost ? 'white' : '',

      };
    },
  },
  methods: {
    autoSelectColumns() {
      Object.keys(this.mapheaders).forEach((key) => {
        const header = this.mapheaders[key];
        const column = this.columns.find((col) => col.name === header);
        if (column) {
          this.match[key] = column;
        }
      });
    },
    handleBack() {
      this.$emit('handleBack');
    },
    uploadFile() {
      this.inventoryStore.loader_upload = true;
      this.$emit('uploadFile', this.match, this.columns);
    },
  },
};
</script>
<style>
.textColor {
  color: white;
};
</style>
