/* eslint-disable */
import axios from '@/plugins/axios'


export default {

  getTrucks() {
    return axios.get('/v1/trucks');
  },
  getCoupled() {
    return axios.get('/v1/get-coupled');
  },
  getUsers() {
    return axios.get('/v1/users');
  },

  getSpecialists(params) {
    let targetParams = {
      ...params
    }
    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key === 'workshop') {
          targetFilter['filter[WorkshopId]'] = targetParams.workshop
        }
      }
    }
    return axios.get('/v1/specialists', {
      params: targetFilter
    });
  },
  getDrivers() {
    return axios.get('/v1/drivers');
  },
  getLocations() {
    console.log('Loading locations');
    return axios.get('/v1/locations_by_company');
  },
  getAllOts() {
    return axios.get('/v1/order_workers?page[size]=*');
  },
  getOtsById(WorkOrderId) {
    return axios.get(`/v1/order_workers/${WorkOrderId}`);
  },
  createOts(WorkOrderId) {
    return axios.post('/v1/order_workers', WorkOrderId);
  },
  updateOts(WorkOrderId, WorkOrder) {
    return axios.put(`/v1/order_workers/${WorkOrderId}`, WorkOrder);
  },
  deleteSupplyOrderWork(id) {
    return axios.delete(`/v1/order_workers/supply/${id}`);
  },
  deleteOts(WorkOrderId) {
    return axios.delete(`/v1/order_workers/${WorkOrderId}`);
  },
  deleteWork(WorkId) {
    return axios.delete(`/v2/order_workers/work/${WorkId}`);
  },
  syncCards(data) {
    return axios.put(`/v1/sync_cards`, {
      works_orders: data
    });
  },
  loadInitialPositions(initialPositions) {
    return axios.put(`/v1/load_initial_positions`, {
      initialPositions: initialPositions
    });
  },
  getOtsByStatus(params) {
    console.log(params);

    let targetParams = {
      ...params
    }

    if (targetParams.from != '') {
      targetParams.from = targetParams.from + ' 00:00:00'
    }
    if (targetParams.to != '') {
      targetParams.to = targetParams.to + ' 23:59:59'
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key === 'from' || key === 'to') {
          targetFilter['filter[BetweenDate]'] = targetParams.from + ',' + targetParams.to
        }

        if (key === 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck
        }
        if (key === 'location' || key === null) {
          targetFilter['filter[WorkshopId]'] = targetParams.location && targetParams.location.id
        }
        if (key === 'search') {
          targetFilter['filter[CodeOts]'] = targetParams.search
        }
        if (key === 'responsable') {
          targetFilter['filter[Specialist]'] = targetParams.responsable
        }
        if (key === 'applicant') {
          targetFilter['filter[Applicant]'] = targetParams.applicant
        }
        if (key === 'review_by') {
          targetFilter['filter[Reviewed]'] = targetParams.review_by
        }
        if (key === 'delivered_to') {
          targetFilter['filter[Delivered]'] = targetParams.delivered_to
        }
        if (key === 'status') {
          targetFilter['filter[Status]'] = targetParams.status
        }
        if (key === 'planned') {
          targetFilter['filter[Planned]'] = targetParams.planned
        }

        let pageType = targetParams.status;

        if (pageType === 'PENDING') {
          targetFilter['page[number]'] = targetParams.page_pending
        }

        if (pageType === 'IN_PROCESS') {
          targetFilter['page[number]'] = targetParams.page_in_proccess
        }

        if (pageType === 'IN_REVIEW') {
          targetFilter['page[number]'] = targetParams.page_in_review
        }

        if (pageType === 'FINALIZED') {
          targetFilter['page[number]'] = targetParams.page_finalized
        }

      }
    }


    return axios.get(`/v2/order_workers_by_group`, {
      params: targetFilter
    });
  },
  updateStatusOts(WorkOrderId, status, positions) {
    return axios.put(`/v1/update_status/${WorkOrderId}`, {
      status: status,
      positions: positions
    });
  },
  getHistoryOrderWorks(params) {
    let targetParams = {
      ...params
    }

    if (targetParams.from != '') {
      targetParams.from = targetParams.from + ' 00:00:00'
    }
    if (targetParams.to != '') {
      targetParams.to = targetParams.to + ' 23:59:59'
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'from' || key == 'to') {
          targetFilter['filter[BetweenDate]'] = targetParams.from + ',' + targetParams.to
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck
        }
        if (key == 'location' || key == null) {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
        if (key == 'responsable') {
          targetFilter['filter[Specialist]'] = targetParams.responsable
        }
        if (key == 'applicant') {
          targetFilter['filter[Applicant]'] = targetParams.applicant
        }
        if (key == 'review_by') {
          targetFilter['filter[Reviewed]'] = targetParams.review_by
        }
        if (key == 'delivered_to') {
          targetFilter['filter[Delivered]'] = targetParams.delivered_to
        }
        if (key == 'search') {
          targetFilter['filter[CodeOts]'] = targetParams.search
        }
        if (key == 'sort_by') {
          targetFilter['sort'] = targetParams.sort_by;
        }
        if (key == 'page') {
          targetFilter.page = targetParams.page;
        }
        if (key == 'per_page') {
          targetFilter.per_page = targetParams.per_page;
        }
        if (key === 'planned') {
          targetFilter['filter[Planned]'] = targetParams.planned
        }
      }
    }

    return axios.get(`/v1/history_order_workers`, {
      params: targetFilter
    });
  },
  createTask(data, companyParam, workshopParam) {

    const postData = {
      ...data,
      company: companyParam,
      workshop: workshopParam
    };

    return axios.post(`/v1/tasks`, postData);
  },
  getTasks(params) {

    return axios.get('/v1/tasks');
  },
  getAllOtsCalendar(params) {
    let targetParams = {
      ...params
    }


    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'start' || key == 'end') {
          targetFilter['filter[BetweenDate]'] = targetParams.start + ',' + targetParams.end
        }
        if (key == 'specialist') {

          targetFilter['filter[FilterSpecialists]'] = `${targetParams.specialist.join(',')}`;
        }

        if (key == 'box_id') {
          targetFilter['filter[Box]'] = targetParams.box_id
        }

        if (key === 'planned') {
          targetFilter['filter[Planned]'] = targetParams.planned
        }

      }
    }
    return axios.get('/v2/calendar_order_work', {
      params: targetFilter
    });
  },
  getAllWorksCalendar(params) {
    console.log(params);

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'start' || key == 'end') {
          targetFilter['filter[BetweenDateWork]'] = targetParams.start + ',' + targetParams.end
        }

        if (key == 'specialist') {

          targetFilter['filter[FilterSpecialists]'] = `${targetParams.specialist.join(',')}`;

        }

      }
    }
    return axios.get('/v2/calendar_works', {
      params: targetFilter
    });

  },
  getBoxes() {
    return axios.get('/v1/boxes');
  },
  getTypesMaintenance() {
    return axios.get('/v2/types_maintenance');
  },
  createTypeMaintenance(typeMaintenance) {
    return axios.post('/v2/types_maintenance', typeMaintenance);
  },
  updateTypeMaintenance(typeMaintenance) {
    return axios.put(`/v2/types_maintenance/${typeMaintenance.id}`, typeMaintenance);
  },
  deleteTypeMaintenance(id) {
    return axios.delete(`/v2/types_maintenance/${id}`);
  }
};
