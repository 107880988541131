/* eslint-disable */
import axios from '@/plugins/axios'

export default {

  getSupplies(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {

      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {

        if (key == 'search') {
          targetFilter['filter[Search]'] = targetParams.search && targetParams.search
        }
        if (key == 'val') {
          targetFilter['page'] = targetParams.val && targetParams.val.page
        }

      }
    }

    return axios.get('/v1/supplies', {
      params: targetFilter
    });
  },

  getSuppliesFromWarehouse(warehouse, params) {
    return axios.get(`/v1/supplie_from_warehouse`, {
      params: {
        warehouseId: warehouse,
        page: params.page,
        perPage: params.perPage,
        search: params.search,
      }
    });
  },
  getSupplie(id) {
    return axios.get(`/v1/supplies/${id}`);
  },
  getSuppliesbyWorkshop(workshop, params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {

      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {

        if (key == 'search') {
          targetFilter['filter[Search]'] = targetParams.search && targetParams.search
        }
      }
      if (workshop) {
        targetFilter['filter[WorkshopId]'] = workshop
      }

    }

    return axios.get(`/v1/supplies`, {
      params: targetFilter
    });
  },
  getOtsById(WorkOrderId) {
    return axios.get(`/v1/order_workers/${WorkOrderId}`);
  },
  createSupplie(payload) {
    return axios.post('/v1/supplies', payload);
  },
  updateSupplies(supplieId, supplie) {
    return axios.put(`/v1/supplies/${supplieId}`, supplie);
  },
  deleteSupplies(WorkOrderId) {
    return axios.delete(`/v1/supplies/${WorkOrderId}`);
  },

  getMeasurementUnits(workshopSelected) {
    console.log('Loading measurement units');
    let path = workshopSelected ?
      `/v1/measurement_units?filter[WorkshopId]=${workshopSelected}` :
      '/v1/measurement_units'
    return axios.get(path);
  },

  getCategories(workshopSelected) {
    console.log('Loading categories');
    let path = workshopSelected ?
      `/v1/categories?filter[WorkshopId]=${workshopSelected}` :
      '/v1/categories'
    return axios.get(path);
  },
  deleteSuppliesImported(val) {
    return axios.delete(`/v1/supplies-delete-imported`, {
      params: {
        hash_import: val
      }
    });
  },
  getCurrentimport(user) {
    let path = `/v1/get-current-import`
    return axios.get(path, {
      params:
        { user_id: user }
    });
  },

};
